<template>
  <ion-page>
    <Header title="Persyaratan Layanan" />
    <ion-content :fullscreen="true">
      <div class="container">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero voluptates quia repellendus at labore voluptas a magnam qui ipsam iusto. Labore perferendis quia voluptate omnis commodi in eveniet, debitis ipsum.
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import Header from '@/components/headerPage'
export default {
  components: {
    IonContent,
    IonPage,
    Header
  }
}
</script>

<style>

</style>
